<template>
    <div class="profileOuterBox">
		<div class="fence">
			<div class="verticalLine"></div>
			<red-block class="redBlock"></red-block>
		</div>
		<div class="personCardBox">
			<img src="@/assets/page5/leaderCard.png" class="bgp" alt="">
			<div class="personCard">
				<div class="avatarBox">
					<img class="avatar" :src="dataInput.avatar" alt="">
				</div>
				<div class="infoOuterBox">
					<div class="nameBox">
						<span class="personName">{{dataInput.name}}</span>
					</div>
					<div class="infoRow">
						<div class="tagOuterline">
							<div class="tagInnerline">
								<span class="itemValue">{{dataInput.position}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.profileOuterBox {
	width: 91.84%;
	height: 9.35vh;
	margin-left: 8.16%;
	display: flex;
	justify-content: space-between;

	.fence {
		width: 8.64%;
		height: 100%;
		position: relative;
		.redBlock {
			position: absolute;
			left: 9%;
			width: 100%;
			aspect-ratio: 1/1;
		}
		.verticalLine {
			position: absolute;
			bottom: 0;
			left: 50%;
			height: 100%;
			width: 0;
			border: #ffd03b solid;
			border-width: 0 0 0 0.3px;
			margin: auto;
		}
	}
	
	.personCardBox {
		width: 86.11%;
		height: 84.16%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.bgp {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 0;
		}

		.personCard {
			width: 83.23%;
			height: 65.88%;
			display: flex;
			overflow: hidden;


			.avatarBox {
				height: 100%;
				aspect-ratio: 1/1;

				.avatar {
					width: 100%;
					height: 100%
				}
			}
			.infoOuterBox {
				height: 100%;
				width: 80%;
				margin-left: 6.98%;

				.nameBox {
					width: 100%;
					height: 2.22vh;
					display: flex;
					align-items: center;
					overflow: hidden;

					.personName {
						line-height: 2.22vh;
						font-size: 1.48vh;
						color: #fff;
						font-family: 'pingfangM', sans-serif;
						font-weight: 500;
						overflow: hidden;
						white-space: nowrap;
					}
				}
				.infoRow {
					height: 2.22vh;
					width: 100%;
					display: flex;
					margin-top: 9%;

					.tagOuterline {
						height: 2.22vh;
						border: #ffd03b 0.5px solid;
						border-radius: 1.11vh;
						padding: 0.09vh;

						.tagInnerline {
							height: 1.85vh;
							border: #ff3b3b 0.5px solid;
							border-radius: 0.925vh;
							display: flex;
							align-items: center;
							justify-content: center;

							.itemValue {
								height: 1.85vh;
								margin: 0 0.5em;
								line-height: 1.85vh;
								font-size: 1.3vh;
								color: #fff;
								font-family: 'pingfangSc', sans-serif;
								font-weight: 400;
							}
						}
					}
				}
			}

		}

		.triangleCorner {
			position: absolute;
			width: 0px;
			height: 0px;
			border-style: solid;
			border-width: 0.37vh;
		}
		
	}
}
</style>

<script>
const redBlock = () => import('./redBlock.vue');

export default {
	/*
		输入数据字段：
		
	*/
	components:{
		redBlock
	},
	props:{
		dataInput:{
			type: Object
		}
	},
	data(){
		return {
			genderIcon: ''
		}
	},
	mounted(){
		let gender = this.dataInput.gender;
		if(gender == 0){
			this.genderIcon = require('@/assets/page5/female.png');
		}else{
			this.genderIcon = require('@/assets/page5/male.png');
		}
		return false;
	}
}
</script>

